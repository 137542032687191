<template>
  <div class="d-templates-page pt-6">
    <v-row v-if="currentDealerId">
      <v-col cols="12" md="auto" class="d-templates-col">
        <v-form ref='form' v-if="editedTemplates">
          <div
            v-for="template of editedTemplates"
            :key="template.id"
            class="mb-7"
          >
            <div class="d-template-type-title">
              {{ getTemplateTypeName(template.templateTypeId) }}
            </div>
            <v-row>
              <v-col cols="auto" class="d-email-template-col">
                <div class="d-notification-type-title">{{ $t('notificationType.email') }}</div>
                <v-text-field
                  v-model.trim="template.subject"
                  :rules="rules.subject"
                  :placeholder="$t('template.subject')"
                  outlined
                  dense
                ></v-text-field>
                <d-wysiwyg-editor
                  v-model.trim="template.message"
                ></d-wysiwyg-editor>
              </v-col>
              <v-col cols="auto" class="d-sms-template-col">
                <div class="d-notification-type-title">{{ $t('notificationType.sms') }}</div>
                <v-textarea
                  v-model.trim="template.sms"
                  :rules="rules.sms"
                  rows="9"
                  outlined
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-col>
      <v-col cols="12" md="auto" class="d-save-col">
        <div v-if="editedTemplates">
          <v-btn
            v-text="$t('save')"
            :disabled="!isModelChanged()"
            class="d-btn-1 text-uppercase"
            width="120"
            depressed
            @click="saveChanges"
          ></v-btn>

          <template-placeholders-list/>
        </div>
      </v-col>
    </v-row>

    <div v-else class="d-no-selected-dealer-notice">
      {{ $t('noSelectedDealerNotice') }}
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex'
import { templateSchema, templateType } from '@/constants'

import objectUtil from '@/utils/object.util'

import pageMixin from '@/mixins/page.mixin'
import snackbarMixin from '@/mixins/snackbar.mixin'
import validationMixin from '@/mixins/validation.mixin'
import unsavedChangesMixin from '@/mixins/unsavedChanges.mixin'
import templateTypeMixin from '@/mixins/templateType.mixin'

import DWysiwygEditor from '@/components/DWysiwygEditor'
import TemplatePlaceholdersList from './TemplatePlaceholdersList'


export default {
  components: {
    DWysiwygEditor,
    TemplatePlaceholdersList
  },


  mixins: [
    pageMixin,
    snackbarMixin,
    validationMixin,
    unsavedChangesMixin,
    templateTypeMixin
  ],


  data () {
    return {
      editedTemplates: null
    }
  },


  computed: {
    ...mapGetters('dealer', ['currentDealerId']),
    ...mapGetters('template', ['templates']),

    rulesToApply () {
      const rule = this.rule

      return {
        subject: [rule.required(), rule.maxLength(templateSchema.subjectMaxLength)],
        sms: [rule.required(), rule.maxLength(templateSchema.smsTemplateMaxLength)]
      }
    }
  },


  watch: {
    async currentDealerId () {
      if (!this.currentDealerId) return

      try {
        this.startPageLoading()
        this.editedTemplates = null
        await this.loadTemplates(this.currentDealerId)
        this.initForm()
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      } finally {
        this.stopPageLoading()
      }
    }
  },


  methods: {
    ...mapActions('template', ['loadTemplateTypes', 'loadTemplates', 'updateTemplates']),

    initForm () {
      let templates = this.templates(this.currentDealerId).filter(x => x.templateTypeId !== templateType.bookingConfirmation)
      templates = objectUtil.getObjectCopy(templates)

      // wysiwyg editor wraps the message with <p> tag (if it's missing)
      // this change affects isModelChanged() value, so the form behaves like it was edited, while the user did nothing
      // the fix below helps to avoid the user confusing :)
      const messageRegex = /^(<p>)[\s\S]*(<\/p>)$/g
      for (const template of templates) {
        template.message = template.message || ''
        if (!messageRegex.test(template.message)) {
          template.message = `<p>${template.message}</p>`
        }
      }

      this.editedTemplates = templates
      this.initWatchedModel(this.editedTemplates)
      this.resetValidation()
    },

    async saveChanges () {
      const valid = await this.validate(this.rulesToApply)
      if (!valid || !this.isModelChanged()) return

      try {
        this.showSnackbarProcessing()
        await this.updateTemplates({ dealerId: this.currentDealerId, templates: this.editedTemplates })
        this.initWatchedModel(this.editedTemplates)
        this.showSnackbarSuccess(this.$t('template.templatesUpdated'))
      } catch (error) {
        this.showSnackbarError()
        this.$log.error(error)
      }
    }
  },


  async created () {
    try {
      this.startPageLoading()
      await this.loadTemplateTypes()

      if (this.currentDealerId) {
        await this.loadTemplates(this.currentDealerId)
        this.initForm()
      }
    } catch (error) {
      this.showSnackbarError()
      this.$log.error(error)
    } finally {
      this.stopPageLoading()
    }
  },

  beforeRouteLeave (to, from, next) {
    this.escape(next, () => next(false))
  }
}
</script>
