
const mixin = {
  computed: {
    templatePlaceholders () {
      const items = []

      items.push({ placeholder: '[licenseNumber]', name: this.$t('templatePlaceholder.licenseNumber') })
      items.push({ placeholder: '[brand]', name: this.$t('templatePlaceholder.brand') })
      items.push({ placeholder: '[model]', name: this.$t('templatePlaceholder.model') })
      items.push({ placeholder: '[firstName]', name: this.$t('templatePlaceholder.firstName') })
      items.push({ placeholder: '[lastName]', name: this.$t('templatePlaceholder.lastName') })

      items.push({ placeholder: '[dealer.name]', name: this.$t('templatePlaceholder.dealer_name') })
      items.push({ placeholder: '[dealer.phone]', name: this.$t('templatePlaceholder.dealer_phone') })
      items.push({ placeholder: '[dealer.url]', name: this.$t('templatePlaceholder.dealer_url') })
      items.push({ placeholder: '[dealer.emailAddress]', name: this.$t('templatePlaceholder.dealer_emailAddress') })

      return items
    }
  }
}


export default mixin
