<template>
  <div :class="$attrs.class" class="d-template-placeholders">
    <div class="d-template-placeholders-title">
      {{ this.$t('template.placeholders')}}
    </div>
    <div
      v-for="item of templatePlaceholders"
      :key="item.placeholder"
      class="d-template-placeholders-item"
      @click="onPlaceholderClick(item)"
    >
      <div class="d-template-placeholders-item-text">
        <div class="d-template-placeholders-item-text-name">
          {{ item.name }}
        </div>
        <div class="d-template-placeholders-item-text-placeholder">
          {{ item.placeholder }}
        </div>
      </div>
      <div class="d-template-placeholders-item-icon">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" small>mdi-content-copy</v-icon>
          </template>
          {{ $t('template.clickToCopy') }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>


<script>
import snackbarMixin from '@/mixins/snackbar.mixin'
import templatePlaceholdersMixin from '@/mixins/templatePlaceholders.mixin'

export default {
  mixins: [
    snackbarMixin,
    templatePlaceholdersMixin
  ],


  methods: {
    onPlaceholderClick (item) {
      const el = document.createElement('textarea')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      el.value = item.placeholder
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.showSnackbarSuccess(this.$t('template.copiedToClipboard'))
    }
  }
}
</script>
