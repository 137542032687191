import { mapGetters } from 'vuex'
import { templateType } from '@/constants'


const mixin = {
  computed: {
    ...mapGetters('template', ['templateTypeById'])
  },


  methods: {
    getTemplateTypeName (id) {
      let name = ''

      switch (id) {
        case templateType.bookingConfirmation:
          name = this.$t('templateType.bookingConfirmation')
          break
        case templateType.guaranteeNotification:
          name = this.$t('templateType.guaranteeNotification')
          break
        default:
          name = this.templateTypeById(id)?.name
          this.$log.info(`Template type ${id} is not implemented on front-end`)
      }

      return name
    }
  }
}


export default mixin
