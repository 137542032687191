<template>
  <v-input
    v-model.trim="content"
    :rules="rules"
  >
    <vue-editor
      v-model.trim="content"
      v-bind="$attrs"
      :editor-toolbar="toolbar.length > 0 ? toolbar : defaultToolbar"
      :placeholder="placeholder"
      style="width: 100%"
      @input="onInput"
    ></vue-editor>
  </v-input>
</template>


<script>
import { VueEditor } from 'vue2-editor'


export default {
  components: {
    VueEditor
  },


  props: {
    value: String,
    rules: {
      type: Array,
      default: () => []
    },
    toolbar: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },


  data () {
    return {
      content: this.value,
      defaultToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        ['link']
      ]
    }
  },


  methods: {
    onInput () {
      this.$emit('input', this.content)
    }
  }
}
</script>
